<template>
  <div class="w-md-80 w-lg-65 mx-auto">
    <div
      class="js-slick-carousel slick"
      data-hs-slick-carousel-options="{
               &quot;fade&quot;: true,
               &quot;autoplay&quot;: true,
               &quot;autoplaySpeed&quot;: 7000,
               &quot;dots&quot;: true,
               &quot;dotsClass&quot;: &quot;slick-pagination mt-4&quot;,
               &quot;infinite&quot;: true
             }"
    >
      <!-- <Slick ref="slick" :options="slickOptions"> -->
      <!-- Testimonial -->
      <div v-for="item in items" :key="item.company" class="js-slide">
        <div class="text-center px-2 px-md-0">
          <div class="mb-6">
            <img class="max-w-11rem max-w-md-13rem mx-auto" :src="require('@/assets/img/testimonials/' + item.image_url)" :alt="item.company">
            <div class="space-top-1" />
            <span class="font-size-1">{{ item.industry }}</span>
          </div>
          <blockquote class="text-lh-lg mb-9">{{ item.content }}</blockquote>
          <div class="text-center ml-3">
            <h4 class="mb-0">{{ item.name }}</h4>
            <small class="text-muted">{{ item.job_title }}</small>
          </div>
        </div>
      </div>
      <!-- End Testimonial -->

    </div>
    <!-- </Slick> -->
  </div>
</template>

<script>
const $ = require('jquery')
import '../assets/js/hs.core.js'
import '../assets/js/hs.slick-carousel.js'
import '../assets/vendor/slick-carousel/slick/slick.js'
// import Slick from 'vue-slick'
export default {
  name: 'PartnerSayCarousel',
  components: {
    // Slick
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      slickOptions: {
        prevArrow: false,
        nextArrow: false,
        autoplay: true,
        autoplaySpeed: 7000,
        fade: true,
        dot: true,
        dotsClass: 'slick-pagination mt-4'
      }
    }
  },
  computed: {
    // sortedItems() {
    //   return orderBy(this.items, 'displayOrder')
    // }
  },
  async mounted() {
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
    // $.HSCore.components.HSSlickCarousel.init($('.js-slick-carousel'))
  }
}
</script>
<style lang="scss" scoped>
@import '~slick-carousel/slick/slick.css';
@import url("../assets/vendor/slick-carousel/slick/slick.css");
</style>
